import React from 'react'
import { Link } from 'gatsby'
import * as images from 'images/home'
import Helmet from 'react-helmet'
import { heroKlantenservice } from 'images/hulp/klantenservice'
import Layout from '../../../layouts'
import TrustBoxMiniCarousel from 'components/trustbox-mini-carousel'
import TrustBox from 'components/trustpilot'
import Checkmark from 'components/misc/checkmark'
const {
  logos: { ...logos }
} = images

class DemoPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false,
      activeID: ''
    }

    this.setActive = this.setActive.bind(this)
  }

  setActive(event) {
    let id = event.target.id

    if(id === this.state.activeID) {
      id = ''
    }

    this.setState({
      active: !this.state.active,
      activeID: id
    })
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <main className="demo animated fadeInPage">

          <Helmet>
            <title>Hoe begin je met salarisadministratie? | Employes</title>
            <meta name="description" content="Ga je voor het eerste werknemers aannemen en moet je je salarisadministratie opzetten? Ontdek hoe je dit in een aantal eenvoudige stappen doet." />
            <meta itemprop="name" content="Hoe begin je met salarisadministratie? | Online salarisadministratie | Employes" />
            <meta itemprop="description" content="Ga je voor het eerste werknemers aannemen en moet je je salarisadministratie opzetten? Ontdek hoe je dit in een aantal eenvoudige stappen doet." />
            <meta itemprop="image" content="/static/meta-img.png" />
            <meta name="robots" content="noindex,follow" />
            <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
          </Helmet>

          <header className="header padding-xl">
            <div className="container-md relative">
              <div className="grid yg align-middle">
                <div className="col-6">
                  <div className="title margin-m-bottom">
                    <h5 className="eyebrow turq no-margin">Whitepaper</h5>
                    <h1>Hoe begin je met salarisadministratie?</h1>
                    <p className="streamer margin-s-bottom">Dit is wat je ontdekt in deze whitepaper:</p>
                    <ul className="streamer margin-s-bottom">
                      <li className="streamer">1. Hoe meld je je aan bij de Belastingdienst?</li>
                      <li className="streamer">2. Welke werknemergegevens heb je nodig?</li>
                      <li className="streamer">3. Hoe maak je een loonstrook?</li>
                      <li className="streamer">4. Hoe doe je loonaangifte</li>
                      <li className="streamer">5. Wanneer betaal je je werknemers uit?</li>
                      <li className="streamer">6. Wat is een jaaropgave?</li>
                      <li className="streamer">7. Hoe werkt het in de boekhouding?</li>
                    </ul>
                    <p className="streamer margin-s-bottom">
                    De whitepaper bevat ook een handige checklist.
                    </p>
                  </div>

                </div>

                <div className="col-6" style={{display: 'flex', alignItems: 'center',justifyContent: 'center',flexDirection: 'column'}}>

                  <div className="download-card">
                      <h4 className="margin-s-bottom">Vul je gegevens in om de whitepaper te ontvangen</h4>
                      <div className="form margin-s-bottom">
                        <form name="Whitepaper | Beginnen met salarisadministratie" method="POST" action="" data-netlify="true" data-netlify-honeypot="bot-field" action="/whitepapers/">
                          <input type="hidden" name="form-name" value="Whitepaper | Beginnen met salarisadministratie" />
                          <input type="text" name="firstname" placeholder="Voornaam*" className="margin-xs-bottom" autoFocus required/>
                          {/*<input type="text" name="lastname" placeholder="Achternaam*" className="margin-xs-bottom" required/>*/}
                          <input type="email" name="email" placeholder="Emailadres*" className="margin-xs-bottom" required/>
                          <input type="text" name="phone" placeholder="Telefoonnummer*" className="margin-xs-bottom"/>
                          <input type="text" name="company" placeholder="Bedrijfsnaam*" className="margin-xs-bottom" required/>
                          <button type="submit" className="btn primary lg">Download gratis</button>
                        </form>

                      </div>

                      <div>
                        <Link className="link mulberry" to="/privacy-policy/" >Privacy policy</Link>
                      </div>
                  </div>

                </div>

              </div>
            </div>

          </header>


        </main>
      </Layout>
    )
  }
}

export default DemoPage
